import React, { useState } from 'react'
import { Dropdown, IconButton, Icon } from 'rsuite';
import ReactModal from 'react-modal';
import 'rsuite/dist/styles/rsuite-default.css';
import '../../App.css';
// TODO: This is missing functionality for sub-menu here from SUI core examples.
// The "Publish To Web" item should contain a sub-menu.

function SpotifyFrame() {
    return (
      <iframe style={{ border: 'none' }} src="https://open.spotify.com/embed/playlist/6rdaboKHmnqYUgiOMqgZ6B?utm_source=generator" width="100%" height="352" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    )
}

function ModalView({ isOpen, setIsOpen }) {
    return <ReactModal isOpen={isOpen} style={{
        overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)'
        },
        content: {
            backgroundColor: 'rgba(0,0,0,0.0)',
            border: 'none'
        }
    }}>
        <IconButton appearance="subtle" icon={<Icon icon="close" />} circle onClick={() => setIsOpen(false)}/>
        <SpotifyFrame />
  </ReactModal>
}

const SpotifyButton = ({ items, onPress }) => {

    // const [isOpen, setIsOpen] = useState(false);

    return <div className="App-spotify-dropdown">
        <IconButton appearance="subtle" icon={<Icon icon="spotify" />} circle onClick={() => window.open('https://open.spotify.com/playlist/6rdaboKHmnqYUgiOMqgZ6B?si=9A66IVtNT-ylp_Gfbket5w&pt=35777681f7252f321edbc5dabb8baacd&pi=9JGmgjSuQOup9')}/>
        {/* <ModalView isOpen={isOpen} setIsOpen={setIsOpen} /> */}
    </div>
}

export default SpotifyButton